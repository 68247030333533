<template>
  <span class="caret inline-block font-sans" v-bind:class="{'search-options-open': isOpen }" >&#8744;</span>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.caret {
  transition: all .3s;
}
.caret.search-options-open {
  transform: rotate(-180deg);
}
</style>

<template>
  <span
      class="uppercase text-white px-2 pt-1 rounded-lg"
      :class="active ? `bg-project-${collection}` : 'bg-gray-light text-gray-dark'"
      :title="expandedCollectionName(collection)"
  >
    {{ collection }}
  </span>
</template>

<script>

import { mapState } from 'pinia';
import { useSearchStore } from '@/stores/search';

export default {
  props: {
    collection: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    ...mapState(useSearchStore, ['CollectionConfig']),

    collectionConfig() {
      return this.CollectionConfig;
    }
  },

  methods: {
    expandedCollectionName(collection) {
      return this.collectionConfig[collection].expandedName;
    }
  }
};

</script>

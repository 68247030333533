<template>
  <col :style="backgroundStyle">
</template>

<script>

import { mapState } from 'pinia/dist/pinia';
import { useVariableTimelineStore } from '@/stores/variableTimeline';

export default {
  data() {
    return {
    };
  },

  computed: {
    ...mapState(useVariableTimelineStore, ['minYear', 'maxYear', 'labeledYears', 'tickWidthPx', 'timelineYearHighlight', 'timelineEndLabelSizePx', 'timelineWidthPx']),

    backgroundStyle() {
      const image = this.buildBackgroundImage();

      return {
        background: `url(${image.toDataURL('image/png')}) repeat-y`
      };
    }
  },

  methods: {
    buildBackgroundImage() {
      const height = 10;
      const lineWidth = 1;
      // Order of the timeline is decreasing
      const firstYear = this.maxYear;
      const canvas = document.createElement('canvas');
      canvas.height = height;
      canvas.width = (this.timelineEndLabelSizePx * 2) + this.timelineWidthPx;

      const context = canvas.getContext('2d');
      context.globalAlpha = 0.4;
      context.strokeStyle = 'rgb(156 163 175)';
      context.lineWidth = lineWidth;
      context.setLineDash([3, 2]);
      context.beginPath();

      for (const year of this.labeledYears) {
        const offset = this.timelineEndLabelSizePx + (this.tickWidthPx * Math.abs(firstYear - year)) + (this.tickWidthPx / 2.0);

        context.moveTo(offset, 0);
        context.lineTo(offset, height);
      }

      context.stroke();

      return canvas;
    }
  }
};

</script>

import { defineStore } from 'pinia';
import { useSearchStore } from '@/stores/search';
import {
  routeParmsToInputStorePatch
} from '@/lib/ParameterMapper';

export const useSearchInputStore = defineStore('searchInput', {
  state: () => {
    // Placeholder values.
    // The actual default search params are defined in lib/ParameterMapper.js and calls to `updateParamDefault`
    return {
      query: '',
      includeSvars: false,
      searchMnemonics: true,
      searchLabels: true,
      searchDescriptions: true,
      searchCategories: true,
      maxYear: null,
      minYear: null,
      usOnly: false,
      showFullTimeline: false,
      collections: [],
      selectedCountries: [],
      sort: 'relevance',
      sortDirection: null
    };
  },

  getters: {
    searchCountries() {
      return this.usOnly ? ['us'] : this.selectedCountries;
    },

    validationErrors() {
      const errs = {};

      if (this.maxYear !== null && this.minYear !== null && this.maxYear < this.minYear) {
        errs.years = 'Invalid year range';
      }

      if (this.collections.length === 0) {
        errs.collections = 'Select at least 1 collection';
      }

      if (!this.usOnly && this.selectedCountries.length === 0) {
        errs.selectedCountries = 'Select at least 1 country';
      }

      if (!this.searchMnemonics && !this.searchLabels && !this.searchCategories && !this.searchDescriptions) {
        errs.options = 'Select at least 1 search attribute';
      }

      return errs;
    },

    canSearch() {
      const search = useSearchStore();
      return search.initialized && !search.executingSearch && this.query && this.query.length > 2 && Object.keys(this.validationErrors).length === 0;
    },

    filtersChanged() {
      const route = this.router.currentRoute.value;
      const filtersFromRoute = routeParmsToInputStorePatch(route);
      for (const k of Object.keys(filtersFromRoute)) {
        if (k === 'query') {
          continue;
        }
        let storeValue = this[k];
        let routeValue = filtersFromRoute[k];
        const storeValueIsArray = Array.isArray(storeValue);
        const routeValueIsArray = Array.isArray(routeValue);
        if (storeValueIsArray !== routeValueIsArray) {
          return true;
        }
        if (storeValueIsArray) {
          storeValue = storeValue.sort().join();
          routeValue = routeValue.sort().join();
        }
        if (storeValue !== routeValue) {
          return true;
        }
      }
      return false;
    }
  }
});

<template>
  <div>
    <div v-if="hasError" class="rounded-md bg-red-50 p-4">
      <div class="flex">
        <div class="ml-3">
          <div class="mt-2 text-red-700">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'pinia';
import { useSearchInputStore } from '@/stores/searchInput';

export default {
  props: {
    validationProperty: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapState(useSearchInputStore, ['validationErrors']),

    error() {
      return this.validationErrors[this.validationProperty];
    },

    hasError() {
      return this.error !== null && this.error !== undefined && this.error !== '';
    }
  }
};

</script>

<style lang="scss" scoped>
</style>

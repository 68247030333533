<template>
  <div class="flex flex-nowrap">
    <span class="inline-block text-center shrink-0" :style="labelStyle">{{ startLabel }}</span>
    <div class="grow overflow-hidden">
      <div class="variable-timeline flex flex-nowrap h-full">
        <span @mouseover="setTickHover($event, tick)" @mouseout="setTickHover($event, null)" v-for="tick in ticks" :key="tick.year" :class="tick.classes" class="tick flex items-center grow">
          <span class="inline-block max-w-[2em] mx-auto align-middle w-3/4 h-1 bg-gray-300"></span>
        </span>
      </div>
    </div>
    <span class="inline-block text-center shrink-0" :style="labelStyle">{{ endLabel }}</span>
  </div>
</template>

<script>

import { mapActions, mapState } from 'pinia';
import { useSearchStore } from '@/stores/search';
import { useVariableTimelineStore } from '@/stores/variableTimeline';
import TimelineTick from '@/lib/TimelineTick';

export default {
  props: {
    variable: {
      type: Object,
      required: true
    },

    country: {
      type: Object,
      required: false,
      default: null
    },

    isFirst: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapState(useSearchStore, ['searchResultMaxYear', 'searchResultMinYear', 'samplesForCollection', 'samplesForVariable', 'samplesForCollectionCountry']),
    ...mapState(useVariableTimelineStore, ['timelineEndLabelSizePx']),

    relevantSamples() {
      if (this.country) {
        return this.samplesForCollectionCountry(this.variable.collection, this.country);
      } else {
        return this.samplesForCollection(this.variable.collection);
      }
    },

    availableSamples() {
      const sampSet = new Set();
      for (const s of this.relevantSamples) {
        sampSet.add(s.id);
      }

      return this.samplesForVariable(this.variable).filter(s => sampSet.has(s.id));
    },

    availableYears() {
      return new Set(this.availableSamples.map(s => s.year));
    },

    collectionYears() {
      return new Set(this.relevantSamples.map(s => s.year));
    },

    allYears() {
      const years = [];
      for (let x = this.searchResultMaxYear; x >= this.searchResultMinYear; x--) {
        years.push(x);
      }
      return years;
    },

    startLabel() {
      return this.searchResultMaxYear.toString();
    },

    endLabel() {
      return this.searchResultMinYear.toString();
    },

    labelStyle() {
      return {
        width: `${this.timelineEndLabelSizePx}px`
      };
    },

    ticks() {
      const ticks = this.allYears.map((year, idx) => {
        const isAvailable = this.availableYears.has(year);
        let isCollectionAvailable = isAvailable;

        if (!isAvailable) {
          isCollectionAvailable = this.collectionYears.has(year);
        }

        return new TimelineTick(
          this.variable,
          year,
          isAvailable,
          isCollectionAvailable
        );
      });

      return ticks;
    }
  },

  methods: {
    ...mapActions(useVariableTimelineStore, ['setTimelineHoveredTick']),

    setTickHover(evt, tick) {
      this.setTimelineHoveredTick(tick, () => {
        let position = null;

        const tickEl = evt.target.closest('.tick');
        if (tickEl) {
          const box = tickEl.getBoundingClientRect();
          position = {
            x: ((box.left + box.right) / 2) + window.scrollX,
            y: ((box.top + box.bottom) / 2) + window.scrollY
          };
        }

        return position;
      });
    }
  }
};

</script>

<style lang="scss" scoped>
  .variable-timeline {

    > span {
      transition: transform 0.2s linear;
      transform-origin: center;

      &.available > span {
        @apply h-4;
        @apply bg-searchButton;
      }

      &.collection-available > span {
        @apply h-4;
      }
    }
  }

  @for $year from 1700 through 2050 {
    .yr#{$year} .yr#{$year} {
      transform: scale(1.25);
    }
  }

</style>

<template>
  <tr @click="handleRowClick">
    <td>
      <a class="mnemonicLink" v-bind:href="variableUrl(variable.mnemonic, variable.collection)" target="_blank">{{ variable.mnemonic }}
      <span v-if="variable.long_mnemonic && variable.mnemonic !== variable.long_mnemonic"> [{{ svarAlias(variable.long_mnemonic)}}]</span>
      </a>
    </td>
    <td class="pr-6">{{ variable.label }}</td>
    <td class="py-1"><app-collection-badge :collection="variable.collection"></app-collection-badge></td>
    <td class="relative" v-bind:class="{'cursor-pointer pl-3 multiCountry': hasMultipleCountries }">
      <div v-if="hasMultipleCountries" class="multiCountryLayover "></div>
      <menu-caret :is-open="open" v-if="hasMultipleCountries"></menu-caret>
      <span v-bind:class="{'cursor-pointer text-black italic pl-3': hasMultipleCountries }">{{ countrySummary }}</span>
    </td>
    <td class="p-0 relative" v-bind:class="{'cursor-pointer multiCountry': hasMultipleCountries }">
      <div v-if="hasMultipleCountries" class="multiCountryLayover"></div>
      <variable-timeline v-if="!hasMultipleCountries" :variable="variable" :country="countries[0]" :is-first="isFirst"></variable-timeline>
    </td>
  </tr>
  <template v-if="open">
    <tr v-for="country in countries" :key="country.short_name">
      <td colspan="3"></td>
      <td>{{ country.full_name }}</td>
      <td class="p-0">
        <variable-timeline :variable="variable" :country="country"></variable-timeline>
      </td>
    </tr>
  </template>
</template>

<script>

import AppCollectionBadge from '@/components/AppCollectionBadge';
import VariableTimeline from '@/components/VariableTimeline';
import { mapState } from 'pinia/dist/pinia';
import { useSearchStore } from '@/stores/search';
import MenuCaret from '@/components/MenuCaret';

export default {
  props: {
    variable: {
      type: Object,
      required: true
    },

    isFirst: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      open: false
    };
  },

  computed: {
    ...mapState(useSearchStore, ['countriesForVariable', 'CollectionConfig', 'search', 'searchResultCountries']),

    collectionConfig() {
      return this.CollectionConfig;
    },

    countries() {
      return this.countriesForVariable(this.variable)
        .filter(country => this.searchResultCountries.includes(country.short_name))
        .sort((a, b) => (a.full_name > b.full_name) ? 1 : -1);
    },

    hasMultipleCountries() {
      return this.countries.length > 1;
    },

    countrySummary() {
      if (this.hasMultipleCountries) {
        return `Multiple (${this.countries.length})`;
      } else {
        return this.countries[0]?.full_name;
      }
    }
  },

  methods: {
    variableUrl(mnemonic, collection) {
      return 'https://' + this.collectionConfig[collection].url + '/' + this.collectionConfig[collection].actionName + '-action/variables/' + mnemonic + '#description_section';
    },

    handleRowClick() {
      if (this.hasMultipleCountries) {
        this.open = !this.open;
      }
    },

    svarAlias(longMnemonic) {
      return longMnemonic.slice(longMnemonic.indexOf('_') + 1);
    }
  },

  components: {
    AppCollectionBadge,
    MenuCaret,
    VariableTimeline
  }
};

</script>

<style lang="scss" scoped>
td {
  @apply text-gray-dark pb-4 pt-4 align-top;
  &.multiCountry {
    div.multiCountryLayover {
      @apply absolute inset-0 -z-10 bg-gray-nearwhite hover:bg-gray border-y-4 border-white;
    }
  }
}

tr {
  @apply border-b;
  &:hover {
    div.multiCountryLayover {
      @apply bg-gray-light;
    }
    div.multiCountryLayover ~ span {
      @apply text-blue-800;
    }
  }
}

a.mnemonicLink {
  @apply text-gray-dark font-semibold;

  &:visited {
    @apply text-gray-dark;
  }
  &:hover, &:focus {
    @apply text-blue-800;
  }
}
</style>

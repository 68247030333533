
const DEFAULT_THROTTLE_OPTS = {
  leading: true,
  trailing: true
};

export function throttle(func, waitMs, options) {
  options = Object.assign({}, DEFAULT_THROTTLE_OPTS, options || {});
  let timeoutHandle = null;
  let lastInvocation = 0;
  let context = null;
  let args = null;

  const throttledFunc = function() {
    context = this;
    args = arguments;

    if (timeoutHandle !== null) {
      // Already waiting. args now contains the most recent version for the next invocation
      return;
    }

    const lastInvokeDelta = Date.now() - lastInvocation;

    if (options.leading && lastInvokeDelta >= waitMs) {
      func.apply(context, args);
      lastInvocation = Date.now();
      return;
    }

    // Only schedule a future invocation if we care about trailing edge calls
    if (options.trailing) {
      const delay = lastInvokeDelta > waitMs ? waitMs : waitMs - lastInvokeDelta;

      timeoutHandle = setTimeout(() => {
        func.apply(context, args);
        lastInvocation = Date.now();
        timeoutHandle = null;
      }, delay);
    }
  };

  throttledFunc.cancel = function() {
    lastInvocation = 0;
    if (timeoutHandle !== null) {
      clearTimeout(timeoutHandle);
      timeoutHandle = null;
    }
  };

  return throttledFunc;
}

<template>
  <app-header></app-header>
  <router-view v-if="error === null"></router-view>
  <div v-else class="w-11/12 md:w-1/2 p-4 m-4 border">
    <app-error></app-error>
  </div>
</template>

<script>

import { mapActions, mapState } from 'pinia';
import { useSearchStore } from '@/stores/search';
import AppHeader from '@/components/AppHeader';
import AppError from '@/components/AppError';

export default {
  computed: {
    ...mapState(useSearchStore, ['error'])
  },

  methods: {
    ...mapActions(useSearchStore, ['setError'])
  },

  mounted() {
    window.onerror = (message, source, lineno, colno, error) => {
      if (!error) {
        error = {
          name: 'Error',
          message: message,
          fileName: source,
          lineNumber: lineno,
          columnNumber: colno
        };
      }

      // Ignore these errors
      if (message.toString().match(/Failed to read the 'cssRules' property from 'CSSStyleSheet': Cannot access rules/i)) {
        return;
      }

      this.setError({ error: error });
    };

    window.addEventListener('unhandledrejection', event => {
      const errObj = event.reason;
      this.setError({ error: errObj });
    });
  },

  components: {
    AppHeader,
    AppError
  }
};

</script>

<style lang="scss">
@font-face {
  font-family: 'Cabrito Sans';
  font-weight: 400;
  font-style: normal;
  src:
      url("https://assets.ipums.org/_font/CabritoSansNormReg/cabritosansnormregular-webfont.eot?#iefix") format("embedded-opentype"),
      url("https://assets.ipums.org/_font/CabritoSansNormReg/cabritosansnormregular-webfont.woff2") format("woff2"),
      url("https://assets.ipums.org/_font/CabritoSansNormReg/cabritosansnormregular-webfont.woff") format("woff"),
      url("https://assets.ipums.org/_font/CabritoSansNormReg/cabritosansnormregular-webfont.ttf") format("truetype"),
      url("https://assets.ipums.org/_font/CabritoSansNormReg/cabritosansnormregular-webfont.svg#cabrito_sans_norm_regular") format("svg");
}

@font-face {
  // use for font-medium, has tnums flattened in.
  // this should not be loaded if it isn't used and we shouldn't use it if we don't need it.
  font-family: "Cabrito Sans";
  src: url('https://assets.ipums.org/_font/CabritoSans-webfont/cabritosanscondmedium-webfont.woff2') format('woff2'),
  url('https://assets.ipums.org/_font/CabritoSans-webfont/cabritosanscondmedium-webfont.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Cabrito Sans';
  font-weight: 600;
  src:
      url("https://assets.ipums.org/_font/CabritoSansCondDemi/cabritosansconddemi-webfont.eot?#iefix") format("embedded-opentype"),
      url("https://assets.ipums.org/_font/CabritoSansCondDemi/cabritosansconddemi-webfont.woff2") format("woff2"),
      url("https://assets.ipums.org/_font/CabritoSansCondDemi/cabritosansconddemi-webfont.woff") format("woff"),
      url("https://assets.ipums.org/_font/CabritoSansCondDemi/cabritosansconddemi-webfont.ttf") format("truetype"),
      url("https://assets.ipums.org/_font/CabritoSansCondDemi/cabritosansconddemi-webfont.svg#cabrito_sans_cond_demi") format("svg");
}

@font-face {
  font-family: 'Cabrito Sans';
  font-weight: 700;
  font-style: normal;
  src:
      url("https://assets.ipums.org/_font/CabritoSansCondBold/cabritosanscondbold-webfont.eot?#iefix") format("embedded-opentype"),
      url("https://assets.ipums.org/_font/CabritoSansCondBold/cabritosanscondbold-webfont.woff2") format("woff2"),
      url("https://assets.ipums.org/_font/CabritoSansCondBold/cabritosanscondbold-webfont.woff") format("woff"),
      url("https://assets.ipums.org/_font/CabritoSansCondBold/cabritosanscondbold-webfont.ttf") format("truetype"),
      url("https://assets.ipums.org/_font/CabritoSansCondBold/cabritosanscondbold-webfont.svg#cabrito_sans_cond_bold") format("svg");
}
html {
  font-size: 62.5%;
}
body, input, textarea, button {
  font-family: "Cabrito Sans","cabrito_sans_norm_regular","Open Sans",sans-serif;
  margin: 0;
  font-size: 1.4rem;
}
h1 {
  font-weight: 400;
  font-size: 2.9rem;
  text-transform: uppercase;
  @apply text-title;
}
</style>

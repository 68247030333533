<template>
  <div class="home px-12 pt-6 pb-7">
    <h1>IPUMS DISCOVERY</h1>
    <h2>Search for variables across all IPUMS microdata collections</h2>
    <div id="search" class="mt-4 flex flex-wrap justify-end">
      <input class="grow" id="search-field" ref="search" type="text" v-model="query" placeholder="Enter search term(s)" @keydown.enter="doSearch()" />
      <search-options-dropdown></search-options-dropdown>
      <button class="border-searchButton uppercase rounded-none border-solid" :class="searchButtonClasses" :disabled="!canSearch" type="button" @click="doSearch()">
        <span>Search</span>
      </button>
    </div>
    <search-filters></search-filters>
    <app-import-warning></app-import-warning>
    <div v-if="showSpinner" class="pt-8 pl-8">
      <CogIcon class="w-14 h-14 text-gray animate-spin-slow"></CogIcon>
    </div>
    <div v-if="searchResults !== null && searchResults.length > 0" class="results">
      <span class="block mt-3 mb-3 italic">{{ resultNumbers }}</span>
      <search-results></search-results>
      <div class="mt-6" v-if="searchResultsPage.totalPages > 1">
        <button class="pagingButton" v-if="searchResultsPage.number > 1" @click="changePage(searchResultsPage.number - 1)"><ArrowLeftIcon class="align-text-top inline w-6 h-7"></ArrowLeftIcon><span>  Prev</span></button>
        <span v-if="searchResultsPage.number > 1" class="inline-block w-6"></span>
        <button class="pagingButton"  v-if="searchResultsPage.number < searchResultsPage.totalPages" @click="changePage(searchResultsPage.number + 1)">Next  <ArrowRightIcon class="align-text-top inline w-6 h-7"></ArrowRightIcon></button>
      </div>
    </div>
    <div v-else-if="searchResults !== null">
      No Results
    </div>
  </div>
</template>

<script>

import AppImportWarning from '@/components/AppImportWarning';
import SearchFilters from '@/components/SearchFilters';
import SearchOptionsDropdown from '@/components/SearchOptionsDropdown';
import SearchResults from '@/components/SearchResults';
import { CogIcon, ArrowRightIcon, ArrowLeftIcon } from '@heroicons/vue/solid';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useSearchStore } from '@/stores/search';
import { useSearchInputStore } from '@/stores/searchInput';

export default {
  name: 'SearchPage',

  computed: {
    ...mapState(useSearchStore, [
      'executingSearch',
      'searchResults',
      'searchResultsPage'
    ]),
    ...mapState(useSearchInputStore, ['canSearch']),
    ...mapWritableState(useSearchInputStore, ['query']),

    resultNumbers() {
      const first = (this.searchResultsPage.number - 1) * this.searchResultsPage.size + 1;
      const last = Math.min(this.searchResultsPage.totalCount, this.searchResultsPage.number * this.searchResultsPage.size);
      return `Showing ${first} - ${last} of ${this.searchResultsPage.totalCount} results`;
    },

    showSpinner() {
      return this.executingSearch;
    },

    searchButtonClasses() {
      return {
        'text-gray-disabled': !this.canSearch,
        'bg-gray-100': !this.canSearch,
        'text-white': this.canSearch,
        'bg-searchButton': this.canSearch
      };
    }
  },

  methods: {
    ...mapActions(useSearchStore, [
      'setError',
      'handleRouteChanged',
      'setSearchRoute',
      'initialize'
    ]),

    doSearch(pg) {
      if (!this.canSearch) {
        return;
      }

      this.setSearchRoute({ page: pg });
    },

    changePage(pg) {
      this.doSearch(pg);
    }
  },

  async mounted() {
    this.$nextTick(function() {
      this.$refs.search.focus();
    });
    await this.initialize();

    this.$watch(
      () => this.$route.params,
      () => {
        this.handleRouteChanged();
      },
      { immediate: true }
    );
  },

  components: {
    AppImportWarning,
    SearchFilters,
    SearchOptionsDropdown,
    SearchResults,
    CogIcon,
    ArrowRightIcon,
    ArrowLeftIcon
  }
};
</script>

<style lang="scss" scoped>

#search{
  input, button {
    @apply text-searchBar border-2;
  }
}

#search::v-deep button {
  width: 140px;
  height: 50px;
  min-width: 140px;
}

.pagingButton {
  @apply italic bg-gray-light px-2 py-1 border-gray-light border-solid border-2 align-bottom;
  &:hover {
    @apply text-blue-800 bg-white;
  }
}
</style>

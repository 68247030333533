
let idCounter = 0;

export default class TimelineTick {
  constructor(variable, year, available, collectionAvailable) {
    this.variable = variable;
    this.year = year;
    this.available = available;
    this.collectionAvailable = collectionAvailable;
    this.id = idCounter++;
  }

  get classes() {
    const clss = [];

    if (this.available || this.collectionAvailable) {
      clss.push(`yr${this.year}`);
    }

    if (this.available) {
      clss.push('available');
    } else if (this.collectionAvailable) {
      clss.push('collection-available');
    }

    return clss;
  }
}

import { defineStore } from 'pinia';
import api from '@/lib/Api';
import { useSearchInputStore } from '@/stores/searchInput';
import { inputStoreToRouteParams, routeParamsToPageOptions, routeParmsToInputStorePatch, updateParamDefault } from '@/lib/ParameterMapper';

export const useSearchStore = defineStore('search', {
  state: () => {
    return {
      DEFAULT_TIMELINE_START_YR: 1960,
      OLDEST_SAMPLE_YR: 1850,
      error: null,
      initialized: false,

      executingSearch: false,
      searchResults: null,
      currentImports: [],
      searchResultsPage: {
        number: 1,
        size: 30,
        totalCount: 0,
        totalPages: 1
      },
      searchParamDetails: {
        maxYear: null,
        minYear: null,
        countries: []
      },
      samples: {},
      collectionSamples: {},
      countries: {},
      continents: {},

      CollectionConfig: {
        ahtus: { url: 'www.ahtusdata.org', expandedName: 'American Heritage Time Use Study', actionName: 'ahtus' },
        atus: { url: 'www.atusdata.org', expandedName: 'American Time Use Survey', actionName: 'atus' },
        cps: { url: 'cps.ipums.org', expandedName: 'Current Population Survey', actionName: 'cps' },
        dhs: { url: 'www.idhsdata.org', expandedName: 'Integrated Demographic and Health Surveys', actionName: 'idhs' },
        highered: { url: 'highered.ipums.org', expandedName: 'IPUMS Higher Education', actionName: 'highered' },
        ipumsi: { url: 'international.ipums.org', expandedName: ' IPUMS International Censuses and Surveys', actionName: 'international' },
        meps: { url: 'meps.ipums.org', expandedName: 'Medical Expenditure Panel Survey', actionName: 'meps' },
        mics: { url: 'mics.ipums.org', expandedName: 'Multiple Indicator Cluster Surveys', actionName: 'mics' },
        mtus: { url: 'www.mtusdata.org', expandedName: 'Multinational Time Use Study', actionName: 'mtus' },
        nhis: { url: 'nhis.ipums.org', expandedName: 'National Health Interview Survey', actionName: 'nhis' },
        pma: { url: 'pma.ipums.org', expandedName: 'Performance Monitoring for Action', actionName: 'pma' },
        usa: { url: 'usa.ipums.org', expandedName: 'IPUMS USA Censuses and American Community Survey', actionName: 'usa' }
      }
    };
  },

  getters: {
    samplesForVariable() {
      return (variable) => {
        return variable.sample_ids.map(sid => this.samples[sid]);
      };
    },

    // Returns a map of maps.
    // A map of collections to maps of countries to sample ids
    samplesByCollectionByCountryMap() {
      const collections = new Map();
      // eslint-disable-next-line no-unused-vars
      for (const [id, sample] of Object.entries(this.samples)) {
        let countries = null;
        if (!collections.has(sample.collection)) {
          countries = new Map();
          collections.set(sample.collection, countries);
        } else {
          countries = collections.get(sample.collection);
        }

        let samples = null;

        if (!countries.has(sample.country_id)) {
          samples = [];
          countries.set(sample.country_id, samples);
        } else {
          samples = countries.get(sample.country_id);
        }
        samples.push(sample);
      }

      return collections;
    },

    countriesByVariableIdMap() {
      const map = new Map();
      for (const v of (this.searchResults || [])) {
        const samples = this.samplesForVariable(v);
        const countryIds = new Set(samples.map(s => s.country_id));
        map.set(v.id, [...countryIds.values()]);
      }
      return map;
    },

    allCountryCodes() {
      return Object.keys(this.countries).map(cid => this.countries[cid].short_name);
    },

    countriesForVariable() {
      return (variable) => {
        return this.countriesByVariableIdMap.get(variable.id).map(cid => this.countries[cid]);
      };
    },

    samplesForCollection() {
      return (collection) => {
        const sids = this.collectionSamples[collection] || [];
        return sids.map(id => this.samples[id]);
      };
    },

    samplesForCollectionCountry() {
      return (collection, country) => {
        return this.samplesByCollectionByCountryMap.get(collection).get(country.id);
      };
    },

    searchResultsCollections() {
      const c = new Set();
      for (const v of (this.searchResults || [])) {
        c.add(v.collection);
      }
      return [...c.values()];
    },

    searchResultMaxYear() {
      const possibleMaxYears = [new Date().getFullYear()];
      let max = null;
      for (const c of this.searchResultsCollections) {
        for (const s of this.samplesForCollection(c)) {
          max = Math.max(s.year, max || 0);
        }
      }
      if (max) {
        possibleMaxYears.push(max);
      }
      if (this.searchParamDetails.maxYear) {
        possibleMaxYears.push(Number(this.searchParamDetails.maxYear));
      }

      return Math.min(...possibleMaxYears);
    },

    searchResultMinYear() {
      const possibleMinYears = [this.OLDEST_SAMPLE_YR];
      let min = null;
      for (const c of this.searchResultsCollections) {
        for (const s of this.samplesForCollection(c)) {
          min = Math.min(s.year, min || 10000);
        }
      }
      if (min) {
        possibleMinYears.push(min);
      }
      if (this.searchParamDetails.minYear) {
        possibleMinYears.push(Number(this.searchParamDetails.minYear));
      }
      return Math.max(...possibleMinYears);
    },

    searchResultCountries() {
      return this.searchParamDetails.countries;
    }
  },

  actions: {
    setError(payload) {
      console.log(payload);
      this.error = payload.error;
    },

    handleRouteChanged() {
      const inputStore = useSearchInputStore();
      // currentRoute is wrapped in a proxy object for some reason; not sure why this is needed.
      const route = this.router.currentRoute.value;
      const patch = routeParmsToInputStorePatch(route);

      inputStore.$patch(patch);

      const pageOpts = routeParamsToPageOptions(route);

      return this.performSearch(pageOpts);
    },

    setSearchRoute(payload) {
      const inputStore = useSearchInputStore();
      const routeParams = inputStoreToRouteParams(inputStore);

      if (payload.page) {
        routeParams.page = payload.page;
      }

      this.router.push({ path: '/', query: routeParams });
    },

    async initialize() {
      await this.loadCountries();
      this.setDefaultParameters();
      this.initialized = true;
    },

    setDefaultParameters() {
      updateParamDefault('collections', Object.keys(this.CollectionConfig));
      updateParamDefault('selectedCountries', this.allCountryCodes);
      updateParamDefault('minYear', this.DEFAULT_TIMELINE_START_YR);
    },

    async performSearch(pageOptions) {
      const inputStore = useSearchInputStore();

      // If there is no query or any validation errors, don't execute search
      if (!inputStore.canSearch) {
        return;
      }

      this.executingSearch = true;
      this.searchResults = null;

      let collections = null;
      let countries = null;

      if (inputStore.collections.length !== Object.keys(this.CollectionConfig).length) {
        collections = inputStore.collections;
      }

      if (inputStore.searchCountries.length !== this.allCountryCodes.length) {
        countries = inputStore.searchCountries;
      }

      try {
        const searchResults = await api.variableSearch(
          inputStore.query,
          inputStore.includeSvars,
          collections,
          inputStore.searchMnemonics,
          inputStore.searchLabels,
          inputStore.searchDescriptions,
          inputStore.searchCategories,
          inputStore.minYear,
          inputStore.maxYear,
          countries,
          inputStore.sort,
          inputStore.sortDirection,
          pageOptions.page,
          pageOptions.size
        );

        const foundCollections = new Set(searchResults.data.map(v => v.collection));
        await this.ensureSamples([...foundCollections.values()]);

        const patch = {
          searchResults: searchResults.data,
          searchResultsPage: {
            number: searchResults.page.number,
            size: searchResults.page.size,
            totalCount: searchResults.page.total_count,
            totalPages: searchResults.page.total_pages
          },
          searchParamDetails: {
            minYear: inputStore.minYear,
            maxYear: inputStore.maxYear,
            countries: inputStore.searchCountries
          },
          currentImports: searchResults.current_imports,
          executingSearch: false
        };

        this.$patch(patch);
      } catch (err) {
        this.executingSearch = false;
        this.setError({ error: err });
      }
    },

    async ensureSamples(collections) {
      try {
        const missingCollections = new Set();

        for (const c of collections) {
          if (!this.collectionSamples[c]) {
            missingCollections.add(c);
          }
        }

        if (missingCollections.size > 0) {
          const data = await api.getSamples([...missingCollections.values()]);
          this.addSamples({ samples: data.samples });
        }
      } catch (err) {
        this.setError({ error: err });
      }
    },

    async loadCountries() {
      const countries = {};
      const continents = {};
      await api.getCountries().then((response) => {
        response.countries.forEach(c => {
          countries[c.id] = c;
          if (!continents[c.continent]) {
            continents[c.continent] = [];
          }
          continents[c.continent].push(c);
        });
      });
      this.countries = countries;
      this.continents = continents;
    },

    addSamples(payload) {
      const samples = payload.samples;
      const newSamples = Object.assign({}, this.samples);
      const newCollectionSamples = Object.assign({}, this.collectionSamples);
      const map = new Map();
      samples.forEach(s => {
        newSamples[s.id] = s;
        if (!map.has(s.collection)) {
          map.set(s.collection, []);
        }
        map.get(s.collection).push(s.id);
      });

      for (const c of map.keys()) {
        newCollectionSamples[c] = map.get(c);
      }

      this.samples = newSamples;
      this.collectionSamples = newCollectionSamples;
    }
  }
});

<template>
  <div class="rounded-md bg-red-50 p-4">
    <div class="flex">
      <div class="ml-3">
        <h3 class="font-medium text-red-800">An Error Has Occurred</h3>
        <div class="mt-2 text-red-700">
          Refresh the page to try again.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'pinia';
import { useSearchStore } from '@/stores/search';

export default {
  computed: {
    ...mapState(useSearchStore, ['error'])
  }
};

</script>

<style lang="scss" scoped>
</style>

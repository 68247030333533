import { defineStore } from 'pinia';
import { useSearchStore } from '@/stores/search';
import { throttle } from '@/lib/FunctionThottle';

export const useVariableTimelineStore = defineStore('variableTimeline', {
  state: () => {
    return {
      timelineEndLabelSizePx: 40,
      timelineWidthPx: 0,
      timelineHoveredTick: null,
      timelineHoveredTickPosition: null
    };
  },

  getters: {
    minYear() {
      const search = useSearchStore();
      return search.searchResultMinYear;
    },

    maxYear() {
      const search = useSearchStore();
      return search.searchResultMaxYear;
    },

    timelineYearHighlight() {
      return this.timelineHoveredTick !== null ? this.timelineHoveredTick.year : null;
    },

    timelineTickCollectionAvailable() {
      return this.timelineHoveredTick.collectionAvailable;
    },

    timelineTickAvailable() {
      return this.timelineHoveredTick.available;
    },

    yearCount() {
      return this.maxYear - this.minYear + 1;
    },

    labelYearInterval() {
      if (this.timelineWidthPx === 0) {
        return 10;
      }
      let spacing = 10;

      // Prevent years labels from being less than ~35px each
      let sizeIterationCount = 0;
      while (this.timelineWidthPx / ((this.yearCount / spacing)) < 35) {
        spacing = spacing + 5;
        sizeIterationCount++;

        if (sizeIterationCount > 50) {
          console.log('Something is very wrong with the labelYearInterval calculation in VariableTimeHeader');
          break;
        }
      }

      return spacing;
    },

    labeledYears() {
      const years = [];

      for (let year = this.maxYear; year >= this.minYear; year--) {
        if (year % this.labelYearInterval === 0) {
          years.push(year);
        }
      }
      return years;
    },

    tickWidthPx() {
      return this.timelineWidthPx / this.yearCount;
    },

    yearLabelWidth() {
      return this.tickWidthPx * this.labelYearInterval;
    },

    firstYearOffset() {
      const countOffset = this.maxYear % this.labelYearInterval;
      const countOffsetPx = countOffset * this.tickWidthPx;
      const labelOffsetPx = this.timelineEndLabelSizePx - (this.yearLabelWidth / 2);
      const centerLabelOffsetPx = this.tickWidthPx / 2.0;
      return countOffsetPx + labelOffsetPx + centerLabelOffsetPx;
    }
  },

  actions: {
    // Set the hovered tick.
    // Since this is connected to the mouseover/mouseout events of each individual tick mark, we expect _lots_ of events
    // as the cursor is moved around. To mitigate any performance issues:
    // 1. Only execute the tick position calculation if the tick has changed (a tick is 2 elements; each one could generate a mouseover event)
    // 2. Put a delay on setting the tick to null. Most mouseover events will be preceded by a mouseout event from the previous tick.
    //    Only set the tick to null if a mouseout event isn't followed by a mouseover event within 150ms.
    setTimelineHoveredTick: (() => {
      const setterFunc = function(tick, positionFunc) {
        if (this.timelineHoveredTick?.id !== tick?.id) {
          let position = null;

          if (tick !== null) {
            position = positionFunc();
          }

          this.timelineHoveredTick = tick;
          this.timelineHoveredTickPosition = position;
        }
      };

      const throttledFunc = throttle(setterFunc, 150, { leading: false });

      return function(tick, positionFunc) {
        if (tick === null) {
          throttledFunc.apply(this, arguments);
        } else {
          throttledFunc.cancel();
          setterFunc.apply(this, arguments);
        }
      };
    })()
  }
});

// Define all search parameters, their default values, and how to map them to and from route parameters

const SERIALIZERS = {
  identity: v => v,
  toBool: v => ['true', 't', '1', 1, true].includes(v),
  toInt: v => parseInt(v),
  toStringArray: v => v.split(','),
  fromStringArray: v => v.join(',')
};

class SearchParam {
  constructor(name, defaultValue, deserialize, serialize) {
    this.name = name;
    this.defaultValue = defaultValue;
    this.deserialize = deserialize || SERIALIZERS.identity;
    this.serialize = serialize || SERIALIZERS.identity;
  }
}

const SEARCH_PARAMS = [
  new SearchParam('query', ''),
  new SearchParam('includeSvars', false, SERIALIZERS.toBool),
  new SearchParam('searchMnemonics', true, SERIALIZERS.toBool),
  new SearchParam('searchLabels', true, SERIALIZERS.toBool),
  new SearchParam('searchDescriptions', true, SERIALIZERS.toBool),
  new SearchParam('searchCategories', true, SERIALIZERS.toBool),
  new SearchParam('maxYear', new Date().getFullYear(), SERIALIZERS.toInt),
  new SearchParam('minYear', 1850, SERIALIZERS.toInt),
  new SearchParam('usOnly', false, SERIALIZERS.toBool),
  new SearchParam('showFullTimeline', false, SERIALIZERS.toBool),
  new SearchParam('sort', 'collection'),
  new SearchParam('sortDirection', null),
  new SearchParam('collections', [], SERIALIZERS.toStringArray, SERIALIZERS.fromStringArray),
  new SearchParam('selectedCountries', [], SERIALIZERS.toStringArray, SERIALIZERS.fromStringArray)
];

const DEFAULT_PAGE_SIZE = 30;

function compareArrays(a1, a2) {
  const superSet = new Map();

  for (const x of a1) {
    superSet.set(x, 1);
  }

  for (const x of a2) {
    if (!superSet.has(x)) {
      return false;
    }
    superSet.set(x, 2);
  }

  for (const v of [...superSet.values()]) {
    if (v === 1) {
      return false;
    }
  }

  return true;
}

function isDefault(param, value) {
  if (Array.isArray(param.defaultValue) && Array.isArray(value)) {
    return compareArrays(param.defaultValue, value);
  } else {
    return param.defaultValue === value;
  }
}

export function updateParamDefault(paramName, defaultValue) {
  const param = SEARCH_PARAMS.find(p => p.name === paramName);
  if (param === undefined) {
    throw new Error(`Invalid param name: ${paramName}`);
  }
  param.defaultValue = defaultValue;
}

export function inputStoreToRouteParams(inputStore) {
  const routeParams = {};

  for (const param of SEARCH_PARAMS) {
    const val = inputStore[param.name];
    if (val !== undefined && val !== null && val !== '' && !isDefault(param, val)) {
      routeParams[param.name] = param.serialize(val);
    }
  }

  if (inputStore.usOnly) {
    delete routeParams.selectedCountries;
  }

  return routeParams;
}

export function routeParmsToInputStorePatch(route) {
  const patch = {};

  for (const param of SEARCH_PARAMS) {
    let val = route.query[param.name];
    if (val === undefined || val === null || val === '') {
      val = param.defaultValue;
    } else {
      val = param.deserialize(val);
    }

    patch[param.name] = val;
  }

  return patch;
}

export function routeParamsToPageOptions(route) {
  const params = route.query;
  const pageOpts = {
    page: 1,
    size: DEFAULT_PAGE_SIZE
  };

  if (params.page) {
    pageOpts.page = parseInt(params.page);
  }

  if (params.size) {
    pageOpts.size = parseInt(params.size);
  }

  return pageOpts;
}

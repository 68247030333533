<template>
  <div>
    <div v-if="multiple">
      <a v-if="disabled" href="#" @click.prevent class="disabled">(Deselect all)</a>
      <a v-else-if="!allSelected" href="#"  @click.prevent="selectAll">(Select all)</a>
      <a v-else href="#" @click.prevent="unselectAll">(Deselect all)</a>
    </div>
    <Listbox :model-value="modelValue" @update:modelValue="onUpdateModelValue" :multiple="multiple" :disabled="disabled">
      <ListboxButton v-slot="{open}" :disabled="disabled" :class="buttonClass" class="mt-1 text-left bg-white border border-gray px-3 flex justify-between">
        <span v-if="multiple" class="italic block text-black" :class="{ 'text-black': !disabled, 'text-gray-disabled': disabled }">{{ buttonLabel }}</span>
        <span v-else>{{ selectedOptions[0]?.label }}</span>
        <menu-caret class="ml-4 -mt-1 block" :class="{ 'text-black': !disabled, 'text-gray-disabled': disabled }" :is-open="open"/>
      </ListboxButton>
      <div ref="spacerDiv"></div>

      <app-dropdown-transition>
        <ListboxOptions class="border border-gray shadow-lg overflow-auto z-10 absolute bg-white" :style="optionsStyle">
          <template v-for="option in options" :key="option.value">
            <template v-if="option.children">
              <li class="cursor-default pl-2 flex items-center bg-white text-gray font-semibold">
                <span>{{ option.label }}</span>
              </li>
              <app-dropdown-item
                  v-for="childOpt in option.children"
                  :key="childOpt.value"
                  :option="childOpt"
                  v-slot="{ active, selected }"
              >
                <slot name="item" :active="active" :selected="selected" :option="childOpt" :value="childOpt.value" :label="childOpt.label"></slot>
              </app-dropdown-item>
            </template>

            <app-dropdown-item v-else :option="option" v-slot="{ active, selected }">
              <slot name="item" :active="active" :selected="selected" :option="option" :value="option.value" :label="option.label"></slot>
            </app-dropdown-item>
          </template>
        </ListboxOptions>
      </app-dropdown-transition>
    </Listbox>
  </div>
</template>

<script>

import AppDropdownItem from '@/components/AppDropdownItem';
import AppDropdownTransition from '@/components/AppDropdownTransition';
import MenuCaret from '@/components/MenuCaret';
import { Listbox, ListboxButton, ListboxOptions } from '@headlessui/vue';
import { throttle } from '@/lib/FunctionThottle';

export default {
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      default: null
    },

    options: {
      type: Array,
      default: () => []
    },

    multiple: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    buttonClass: {
      default: null
    }
  },

  data() {
    return {
      maxDropdownHeight: 1000
    };
  },

  computed: {
    buttonLabel() {
      let label = `Select ${this.label}`;

      if (!this.disabled) {
        label = `${label} (${this.modelValue.length} of ${this.optionCount})`;
      }

      return label;
    },

    optionsStyle() {
      const min = 150;
      const max = 650;

      const maxH = Math.min(Math.max(this.maxDropdownHeight, min), max);

      return {
        'max-height': `${maxH}px`
      };
    },

    selectedOptions() {
      let selected = this.modelValue;
      if (!Array.isArray(selected)) {
        selected = [selected];
      }

      return this.flatOptions.filter(opt => selected.includes(opt.value));
    },

    allSelected() {
      return this.selectedOptions.length === this.flatOptions.length;
    },

    flatOptions() {
      const opts = [];

      for (const opt of this.options) {
        if (opt.children) {
          opts.splice(opts.length, 0, ...opt.children);
        } else {
          opts.push(opt);
        }
      }

      return opts;
    },

    optionCount() {
      return this.flatOptions.length;
    }
  },

  methods: {
    onUpdateModelValue(value) {
      this.$emit('update:modelValue', value);
    },

    selectAll() {
      this.$emit('update:modelValue', this.flatOptions.map(opt => opt.value));
    },

    unselectAll() {
      this.$emit('update:modelValue', []);
    },

    recalcDropdownHeight: function() {
      const el = this.$refs.spacerDiv;
      const dropdownBottom = el.getBoundingClientRect().bottom;
      const screenHeight = window.document.documentElement.clientHeight;
      const padding = 20;
      this.maxDropdownHeight = screenHeight - dropdownBottom - padding;
    }
  },

  mounted() {
    this.recalcDropdownHeight();

    this.$throttledRecalcDropdownHeight = throttle(this.recalcDropdownHeight, 100);

    document.addEventListener('scroll', this.$throttledRecalcDropdownHeight);
    window.addEventListener('resize', this.$throttledRecalcDropdownHeight);
  },

  unmounted() {
    document.removeEventListener('scroll', this.$throttledRecalcDropdownHeight);
    window.removeEventListener('resize', this.$throttledRecalcDropdownHeight);
  },

  components: {
    AppDropdownItem,
    AppDropdownTransition,
    Listbox,
    ListboxButton,
    ListboxOptions,
    MenuCaret
  }
};

</script>

<style lang="scss" scoped>

a {
  @apply text-black italic;

  &:visited {
    @apply text-black;
  }
  &:hover, &:focus {
    @apply text-blue-800;
  }
  &.disabled {
    @apply text-gray-disabled;
    &:visited {
      @apply text-gray-disabled;
    }
    &:hover, &:focus {
      @apply text-gray-disabled;
    }
  }
}

</style>

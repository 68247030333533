<template>
  <div class="row" id="header-container">
    <header id="mpcHeader" class="col-xs-12 flex pt-9 pb-10 pl-6">
      <a class="flex items-center" href="https://www.ipums.org">
        <img alt="IPUMS" class="mb-3 h-24 max-w-none" id="ipums-logo" src="../assets/images/logoIPUMS.png">
      </a>
      <div id="project-logos" class="flex flex-wrap ml-10">
        <div class="flex">
          <a href="https://usa.ipums.org/usa/"><img alt="Integrated Public Use Microdata Series (IPUMS) USA" title="Integrated Public Use Microdata Series (IPUMS) USA" src="../assets/images/logo-usa.png"></a>
          <a href="https://cps.ipums.org/cps/"><img alt="Integrated Public Use Microdata Series (IPUMS) CPS" title="Integrated Public Use Microdata Series (IPUMS) CPS" src="../assets/images/logo-cps.png"></a>
          <a href="https://international.ipums.org/international/"><img alt="Integrated Public Use Microdata Series (IPUMS) International" title="Integrated Public Use Microdata Series (IPUMS) International" src="../assets/images/logo-ipumsi.png"></a>
          <a href="https://www.ipums.org/timeuse.html"><img alt="Integrated Public Use Microdata Series (IPUMS) Time Use Projects" title="Integrated Public Use Microdata Series (IPUMS) Time Use Projects" src="../assets/images/logo-time.png"></a>
        </div>
        <div class="flex">
          <a href="https://www.idhsdata.org/idhs/"><img alt="Integrated Demographic and Health Series (IDHS)" title="Integrated Demographic and Health Series (IDHS)" src="../assets/images/logo-dhs.png"></a>
          <a href="https://highered.ipums.org/"><img alt="Integrated Public Use Microdata Series (IPUMS) Higher Ed" title="Integrated Public Use Microdata Series (IPUMS) Higher Ed" src="../assets/images/logo-he.png"></a>
          <a href="https://ihis.ipums.org/ihis/"><img alt="Integrated Health Interview Series (NHIS)" title="Integrated Health Interview Series (NHIS)" src="../assets/images/logo-health.png"></a>
        </div>
      </div>
    </header>
  </div>
</template>

<style lang="scss" scoped>
#mpcHeader {
  background: url("@/assets/images/numbers_ipums.png") bottom left/88.2rem 1.1rem repeat-x;
  @apply bg-background;
}
#project-logos {
  a img {
    @apply h-24 max-w-none ml-3 mb-3;
  }
}
</style>

<template>
  <Teleport to="body">
    <div v-if="yearTooltipVisible" class="w-64 absolute transition-all translate-x-4 translate-y-4 pointer-events-none text-gray-dark bg-gray-nearwhite border rounded-md border-gray p-2" :style="tooltipStyle">
      <div>{{ timelineYearHighlight }}</div>
      <div>
        <CheckIcon v-if="timelineTickAvailable" class="inline-block w-7 h-7 mr-1 text-searchButton" />
        <XIcon v-else class="inline-block w-7 h-7 mr-1 text-gray" />
        <span class="mt-1 inline-block">Variable {{timelineTickAvailable ? 'available' : 'unavailable' }}</span>
      </div>
      <div>
        <CheckIcon v-if="timelineTickCollectionAvailable" class="inline-block w-7 h-7 mr-1 text-searchButton" />
        <XIcon v-else class="inline-block w-7 h-7 mr-1 text-gray" />
        <span class="mt-1 inline-block">Sample {{timelineTickCollectionAvailable ? 'available' : 'unavailable' }}</span>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { mapState } from 'pinia/dist/pinia';
import { useVariableTimelineStore } from '@/stores/variableTimeline';
import { CheckIcon, XIcon } from '@heroicons/vue/solid';

export default {
  components: {
    CheckIcon,
    XIcon
  },
  computed: {
    ...mapState(useVariableTimelineStore, ['timelineYearHighlight', 'timelineHoveredTickPosition', 'timelineTickCollectionAvailable', 'timelineTickAvailable']),

    yearTooltipVisible() {
      return this.timelineYearHighlight !== null && this.timelineHoveredTickPosition !== null;
    },

    tooltipStyle() {
      if (this.timelineHoveredTickPosition) {
        // Offset the tick so it does not overflow the right side of the screen
        // Offset is hardcoded as 200px as $ref.clientWidth was not reliably present (possibly due to mounting order?)
        const possibleX = [this.timelineHoveredTickPosition.x, window.innerWidth - 200];
        const xPosition = Math.min(...possibleX);
        return {
          top: `${this.timelineHoveredTickPosition.y}px`,
          left: `${xPosition}px`
        };
      } else {
        return {};
      }
    }
  }
};

</script>

<template>
  <table class="table-fixed w-[850px] lg:w-full" :class="tableClasses">
    <colgroup>
      <col span="4">
      <variable-timeline-column></variable-timeline-column>
    </colgroup>
    <thead>
    <tr>
      <th class="w-60">Variable Name</th>
      <th class="w-96">Variable Label</th>
      <th class="w-36">Collection</th>
      <th class="w-48">Country</th>
      <th class="p-0 w-[36rem] lg:w-auto"><variable-timeline-header></variable-timeline-header></th>
    </tr>
    </thead>
    <tbody>
      <variable-search-result v-for="(r, idx) in searchResults" :key="r.id" :variable="r" :is-first="idx === 0"></variable-search-result>
    </tbody>
  </table>
</template>

<script>

import VariableSearchResult from '@/components/VariableSearchResult';
import VariableTimelineColumn from '@/components/VariableTimelineColumn';
import VariableTimelineHeader from '@/components/VariableTimelineHeader';
import { mapState } from 'pinia';
import { useSearchStore } from '@/stores/search';
import { useVariableTimelineStore } from '@/stores/variableTimeline';

export default {
  computed: {
    ...mapState(useSearchStore, ['searchResults', 'searchResultMinYear', 'searchResultMaxYear']),
    ...mapState(useVariableTimelineStore, ['timelineYearHighlight']),

    tableClasses() {
      const classes = [];
      if (this.timelineYearHighlight) {
        classes.push(`yr${this.timelineYearHighlight}`);
      }
      return classes;
    }
  },

  components: {
    VariableSearchResult,
    VariableTimelineColumn,
    VariableTimelineHeader
  }
};

</script>

<style lang="scss" scoped>
th {
  @apply text-left align-top font-semibold;
}
</style>

<template>
  <Popover>
    <PopoverButton class="bg-gray-light text-searchBar border-2 uppercase rounded-none" :class="buttonClasses" v-slot="{ open }">
      <span>Options</span>
      <menu-caret class="text-gray ml-4 -mt-1" :is-open="open"/>
    </PopoverButton>

    <app-dropdown-transition>
      <PopoverPanel class="flex absolute z-10">
        <div class="grow" id="search-options-spacer"></div>
        <div class="bg-gray-light mt-2 w-[28rem] border border-black" id="search-options">
          <div class="p-3" id="search-options-content">
            <legend>Search</legend>
            <input type="checkbox" id="searchMnemonics" v-model="searchMnemonics">
            <label for="searchMnemonics">Variable Names</label><br>
            <input type="checkbox" id="searchLabels" v-model="searchLabels">
            <label for="searchLabels">Variable Labels</label><br>
            <input type="checkbox" id="searchDescriptions" v-model="searchDescriptions">
            <label for="searchDescriptions">Variable Descriptions</label><br>
            <input type="checkbox" id="searchCategories" v-model="searchCategories">
            <label for="searchCategories">Value Labels</label><br>
            <input type="checkbox" id="includeSvars" v-model="includeSvars">
            <label for="includeSvars">Source/unharmonized Variables</label><br>

            <fieldset>
              <legend class="pt-2">Sort By</legend>
              <input type="radio" value="relevance" id="sort_relevance" v-model="sort">
              <label for="sort_relevance">Relevance</label><br>
              <input type="radio" value="mnemonic" id="sort_mnemonic" v-model="sort">
              <label for="sort_mnemonic">Variable Name</label><br>
              <input type="radio" value="collection" id="sort_collection" v-model="sort">
              <label for="sort_collection">Collection</label><br>
              <input type="radio" value="sample_count" id="sort_sample_count" v-model="sort">
              <label for="sort_sample_count">Sample Count</label>
            </fieldset>
          </div>
          <app-validation-error validation-property="options"></app-validation-error>
        </div>
      </PopoverPanel>
    </app-dropdown-transition>
  </Popover>
</template>

<script>

import AppDropdownTransition from '@/components/AppDropdownTransition';
import AppValidationError from '@/components/AppValidationError';
import MenuCaret from '@/components/MenuCaret';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { mapState, mapWritableState } from 'pinia';
import { useSearchInputStore } from '@/stores/searchInput';

export default {
  computed: {
    ...mapState(useSearchInputStore, ['validationErrors']),
    ...mapWritableState(useSearchInputStore, [
      'includeSvars',
      'searchMnemonics',
      'searchLabels',
      'searchDescriptions',
      'searchCategories',
      'sort',
      'sortDirection']),

    hasValidationError() {
      return !!this.validationErrors?.options;
    },

    buttonClasses() {
      return {
        'border-gray-light': !this.hasValidationError,
        'border-red-700': this.hasValidationError
      };
    }
  },

  components: {
    AppDropdownTransition,
    Popover,
    PopoverButton,
    PopoverPanel,
    AppValidationError,
    MenuCaret
  }
};

</script>

<style lang="scss" scoped>

  #search-options-content label {
    @apply pl-3;
    text-transform: uppercase;
    font-weight: 500;
  }

</style>

<template>
  <div>
    <div v-if="hasImports" class="rounded-md bg-red-50 p-4">
      <div class="flex">
        <div class="ml-3">
          <h3 class="font-medium text-red-800">Warning</h3>
          <div class="mt-2 text-red-700">
            {{ message }}
            <span v-for="c in currentImports" :key="c">
              <app-collection-badge  :collection="c"></app-collection-badge>&nbsp;
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'pinia';
import { useSearchStore } from '@/stores/search';
import AppCollectionBadge from '@/components/AppCollectionBadge';

export default {
  computed: {
    ...mapState(useSearchStore, ['currentImports']),

    hasImports() {
      return this.currentImports.length > 0;
    },

    message() {
      let msgPart = 'collections are';

      if (this.currentImports.length === 1) {
        msgPart = 'collection is';
      }

      return `The following ${msgPart} currently being indexed and search results may not be accurate:`;
    }
  },

  components: {
    AppCollectionBadge
  }
};

</script>

<style lang="scss" scoped>
</style>

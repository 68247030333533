<template>
  <div class="inset flex flex-col bg-white" ref="wrapper">
    <span>Years data are available</span>
    <div :style="wrapperStyle" class="flex text-center">
      <span v-for="y in labeledYears" :key="y" class="text-gray-dark inline-block flex-1 min-w-0 overflow-hidden">{{ y }}</span>
    </div>
    <variable-timeline-tooltip></variable-timeline-tooltip>
  </div>
</template>

<script>

import VariableTimelineTooltip from '@/components/VariableTimelineTooltip';
import { mapState, mapWritableState } from 'pinia/dist/pinia';
import { useVariableTimelineStore } from '@/stores/variableTimeline';
import { throttle } from '@/lib/FunctionThottle';

export default {
  data() {
    return {
    };
  },

  computed: {
    ...mapState(useVariableTimelineStore, ['firstYearOffset', 'tickWidthPx', 'labelYearInterval', 'labeledYears', 'timelineEndLabelSizePx']),
    ...mapWritableState(useVariableTimelineStore, ['timelineWidthPx']),

    wrapperStyle() {
      return {
        'margin-left': `${this.firstYearOffset}px`,
        width: `${(this.tickWidthPx * this.labelYearInterval) * this.labeledYears.length}px`
      };
    }
  },

  methods: {
    syncWidth() {
      const rect = this.$refs.wrapper.getBoundingClientRect();
      this.timelineWidthPx = rect.width - (this.timelineEndLabelSizePx * 2);
    }
  },

  mounted() {
    this.$resizeObserver = new ResizeObserver(throttle((entries) => {
      this.syncWidth();
    }), 100);

    this.$resizeObserver.observe(this.$refs.wrapper);

    this.syncWidth();
  },

  unmounted() {
    this.$resizeObserver.disconnect();
  },

  components: {
    VariableTimelineTooltip
  }
};

</script>

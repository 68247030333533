<template>
  <div class="bg-gray-light mt-10 p-5" :class="{ invisible: !initialized }">
    <div class="bg-gray-nearwhite inline-block px-3 py-1 rounded-sm">
      <h2 class="uppercase font-semibold">Filters</h2>
    </div>
    <div class="flex mt-6 mb-4" id="search-filters-row-flex">
      <div class="" id="projectFilterContainer">
        <h3 class="mr-4 mb-2">IPUMS Data Collection</h3>

        <app-dropdown v-model="collections" :options="collectionOptions" multiple label="collections" button-class="w-96">
          <template #item="{ selected, active, value, label }">
            <div class="pt-3 pb-2 ml-3 mr-3">
              <div><app-collection-badge :active="selected" :collection="value"></app-collection-badge></div>
              <div class="font-semibold" :class="active ? 'text-white' : 'text-gray-dark'">{{ label }}</div>
            </div>
          </template>
        </app-dropdown>
        <app-validation-error validation-property="collections"></app-validation-error>
      </div>

      <div class="ml-4" id="geographyFiltersContainer">
        <h3 class="mr-4 mb-2">Geography</h3>
        <label for="usOnly">Only show U.S. results</label>
        <input class="ml-3" type="checkbox" id="usOnly" name="usOnly" v-model="usOnly">

        <app-dropdown v-model="selectedCountries" :options="countryOptions" multiple label="countries" :disabled="usOnly" button-class="w-96"></app-dropdown>
        <app-validation-error validation-property="selectedCountries"></app-validation-error>
      </div>
      <div class="ml-4" id="timeFiltersContainer">
        <h3 class="mr-4 mb-2">Year Range</h3>
        <div>
          <label for="showFullTimeline">Show pre-{{ DEFAULT_TIMELINE_START_YR }} results</label>
          <input type="checkbox" class="ml-3" id="showFullTimeline" name="showFullTimeline" v-on:change="handleShowFullTimelineCheckbox($event.target.checked)" :checked="showFullTimeline">
        </div>
        <div class="inline-block align-top">
          <app-dropdown v-model="minYear" :options="yearOptions"></app-dropdown>
        </div>
        <div class="align-top inline-block mx-3 h-11 pt-1">to</div>
        <div class="inline-block align-top">
          <app-dropdown v-model="maxYear" :options="yearOptions"></app-dropdown>
        </div>
        <app-validation-error validation-property="years"></app-validation-error>
      </div>
      <div class="ml-4">
        <div v-if="filtersChanged && canSearch && searchResults !== null && searchResults.length > 0">
          <button @click="doSearch()" class="px-3 py-3 ml-4 bg-searchButton font-semibold text-white" >Apply Filters</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AppCollectionBadge from '@/components/AppCollectionBadge';
import AppDropdown from '@/components/AppDropdown';
import AppValidationError from '@/components/AppValidationError';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useSearchStore } from '@/stores/search';
import { useSearchInputStore } from '@/stores/searchInput';

export default {
  computed: {
    ...mapState(useSearchStore, [
      'DEFAULT_TIMELINE_START_YR',
      'OLDEST_SAMPLE_YR',
      'CollectionConfig',
      'continents',
      'allCountryCodes',
      'initialized',
      'searchResults'
    ]),
    ...mapState(useSearchInputStore, ['canSearch', 'filtersChanged']),

    ...mapWritableState(useSearchInputStore, [
      'maxYear',
      'minYear',
      'usOnly',
      'showFullTimeline',
      'countryCodes',
      'collections',
      'selectedCountries']),

    currentYear() {
      return new Date().getFullYear();
    },

    availableCollections() {
      return Object.keys(this.CollectionConfig);
    },

    collectionOptions() {
      return this.availableCollections.map(c => ({ value: c, label: this.CollectionConfig[c].expandedName }));
    },

    availableContinents() {
      return Object.keys(this.continents).sort();
    },

    countryOptions() {
      const opts = [];

      this.availableContinents.forEach(c => {
        const opt = { value: c, label: c, children: [] };
        opts.push(opt);

        this.continents[c].forEach(country => {
          opt.children.push({ value: country.short_name, label: country.full_name });
        });
      });

      return opts;
    },

    yearSelectionRange() {
      let counter = this.showFullTimeline ? this.OLDEST_SAMPLE_YR : this.DEFAULT_TIMELINE_START_YR;
      const range = [];
      while (counter < this.currentYear) {
        if (this.currentYear - counter > 5) {
          range.unshift(counter);
        } else {
          range.unshift(this.currentYear);
        }
        counter += 10;
      }
      return range;
    },

    yearOptions() {
      const opts = [];

      for (const year of this.yearSelectionRange) {
        opts.push({ value: year, label: year });
      }

      return opts;
    }
  },

  methods: {
    ...mapActions(useSearchStore, [
      'setSearchRoute'
    ]),

    doSearch() {
      if (!this.canSearch) {
        return;
      }
      this.setSearchRoute({});
    },

    handleShowFullTimelineCheckbox(value) {
      this.showFullTimeline = value;
      if (value) {
        this.minYear = this.OLDEST_SAMPLE_YR;
      } else {
        this.minYear = this.DEFAULT_TIMELINE_START_YR;
      }
    }
  },

  components: {
    AppCollectionBadge,
    AppDropdown,
    AppValidationError
  }
};

</script>

<style lang="scss" scoped>

  h3 {
    @apply font-semibold uppercase;
  }

  .label, label {
    text-transform: uppercase;
    font-weight: 500;
  }

</style>

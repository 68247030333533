class Api {
  performRequest(url, method, params = {}, headers = {}) {
    const hasBody = Object.keys(params || {}).length !== 0;

    const reqHeaders = new Headers();
    reqHeaders.append('Accept', 'application/json');
    reqHeaders.append('Content-Type', 'application/json');

    for (const key in headers) {
      reqHeaders.append(key, headers[key]);
    }

    const opts = {
      headers: reqHeaders,
      method: method,
      credentials: 'same-origin'
    };

    if (hasBody) {
      opts.body = JSON.stringify(params);
    }

    return fetch(url, opts).then(this.handleFetchErrors).then(res => res.json());
  }

  async handleFetchErrors(response) {
    if (!response.ok) {
      let err = response.statusText;
      let errJson = null;
      try {
        errJson = await response.json();
        if (errJson.error) {
          err = errJson.error;
        }
        if (errJson.exception) {
          err += '\n' + errJson.exception;
        }
      } catch {}
      throw new Error(err);
    }
    return response;
  }

  objectToUrlParams(obj, queryParams = [], prefixes = []) {
    for (const key in obj) {
      const val = obj[key];
      const paramName = prefixes.join('[') + '['.repeat(Math.min(prefixes.length, 1)) + encodeURIComponent(key) + ']'.repeat(prefixes.length);
      if (Array.isArray(val)) {
        for (const x of val) {
          queryParams.push(paramName + '[]=' + (x === null ? '' : encodeURIComponent(x)));
        }
      } else if (typeof val === 'object') {
        this.objectToUrlParams(val, queryParams, prefixes.concat([key]));
      } else {
        queryParams.push(paramName + '=' + (val === null ? '' : encodeURIComponent(val)));
      }
    }

    return queryParams;
  }

  buildGetUrl(url, params = {}) {
    const queryParams = this.objectToUrlParams(params);
    if (queryParams.length) {
      url = url + '?' + queryParams.join('&');
    }
    return url;
  }

  get(url, params = {}) {
    url = this.buildGetUrl(url, params);
    return this.performRequest(url, 'GET');
  }

  variableSearch(query, includeSvars, includeCollections, searchMnemonics, searchLabels, searchDescriptions, searchCategories, minYear, maxYear, countryCodes, sort, sortDirection, pageNumber, pageSize) {
    const params = {
      query: query,
      svars: includeSvars,
      collections: includeCollections,
      search_mnemonics: searchMnemonics,
      search_labels: searchLabels,
      search_descriptions: searchDescriptions,
      search_categories: searchCategories,
      min_year: minYear,
      max_year: maxYear,
      country_codes: countryCodes,
      sort: sort,
      sort_direction: sortDirection,
      page_number: pageNumber,
      page_size: pageSize
    };

    return this.get('/search', params);
  }

  getSamples(collections) {
    const params = {
      collections
    };

    return this.get('/samples', params);
  }

  getCountries() {
    return this.get('/countries');
  }
}

export default new Api();
